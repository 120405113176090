@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0px;
  margin: 0px;
  scroll-behavior: smooth;
}

.App_container__25HZb {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.Home_container__81NG1 {
  height: 100%;
  width: 100%;

  background-image: url(/static/media/homeBackground2.2d227222.jpg);
  background-repeat: no-repeat;
  background-size: cover;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

/* HEADER */
.Home_header__1U3fC {
  height: 15%;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;

  padding-left: 8vw;
  padding-right: 8vw;
}

.Home_logo__30jtC {
}

.Home_navBar__4KGbg {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.Home_navBar__4KGbg li {
  list-style-type: none;
  padding-left: 3vw;
}

.Home_navBar__4KGbg a,
.Home_navBar__4KGbg a:visited {
  color: white;
  text-decoration: none;
}

.Home_navBar__4KGbg a:hover {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
}

/* BODY */
.Home_body__2ky1h {
  height: 85%;
  padding-left: 8vw;
  padding-right: 8vw;

  font-size: 1.8em;
  border-top: 1px solid white;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.Home_bodyText__289vs {
  padding-bottom: 3vh;
}

.Home_name__2_eDL {
  display: inline;
  font-family: "Shadows Into Light", cursive;
}

/* BODY -> LINKS*/
.Home_links__2MNnE {
  font-size: 0.6em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.Home_links__2MNnE li {
  list-style-type: none;
  padding-right: 2vw;
}

.Home_links__2MNnE a {
  text-decoration: none;
  color: white;
}

.Home_links__2MNnE a:hover {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
}

/* Media Queries */
@media screen and (max-width: 420px) {
  .Home_logo__30jtC {
    font-size: 1.1em;
  }
  .Home_body__2ky1h {
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
  }
  .Home_bodyText__289vs {
    padding-bottom: 0px;
  }
  .Home_bodyText__289vs p {
    padding-bottom: 1.5vh;
  }
  .Home_links__2MNnE {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
  .Home_links__2MNnE li {
    padding-right: 0px;
    padding-bottom: 1.5vh;
  }
  .Home_dash__g0qmZ {
    display: none;
  }
}

.About_container__2Cz7Y {
  height: 100%;
  width: 100%;

  font-size: 0.85vw; /* Not sure if this helps*/

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

/* Generic Box */
.About_box__3hid2 {
  border: 1px solid grey;
  height: 25%;
  color: black;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;

  padding-left: 8vw;
  padding-right: 8vw;
}

.About_box__3hid2:hover {
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  border: none;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  transition: 0.7s;
}

.About_title__1sRO7 {
  padding-top: 4vh;
  padding-bottom: 1.5vh;
  font-size: 2em;
}

/* Box on hover */
.About_box1__4JzUS:hover {
  background-image: url(/static/media/hover4.fadb47c0.jpg);
}
.About_box2__OO2eB:hover {
  background-image: url(/static/media/hover3.484c35d5.jpg);
}
.About_box3__2a27k:hover {
  background-image: url(/static/media/image_part_003.09606774.jpg);
}
.About_box4__nqBQK:hover {
  background-image: url(/static/media/image_part_004.6a37909b.jpg);
}

/* Box 1 */
.About_box1__4JzUS {
}

.About_box1__4JzUS p {
  line-height: 2.2;
}

/* Box 2 */
.About_box2__OO2eB {
}

.About_box2__OO2eB p {
  line-height: 2.2;
}

/* Box 3 */
.About_box3__2a27k {
}

.About_skillsTitle__3V-F9 {
  padding-bottom: 4vh;
}

.About_logosContainer__3WJwL {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.About_skillBox__Ni0-V {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 3vw;
}

.About_logo__25Bpz {
  height: 35px;
  width: 50px;
}

/* Box 4 */
.About_box4__nqBQK {
}

.About_box4__nqBQK p {
  line-height: 1.8;
}

/* Media Queries */
@media screen and (max-width: 1440px) {
  .About_box1__4JzUS p {
    line-height: 1.8;
  }
  .About_box4__nqBQK p {
    line-height: 1.5;
  }
}

@media screen and (max-width: 1280px) {
  .About_container__2Cz7Y {
    font-size: 0.9em;
  }
  .About_title__1sRO7 {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 1024px) {
  .About_logosContainer__3WJwL {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1vh;
  }
  .About_skillsTitle__3V-F9 {
    padding-bottom: 2vh;
  }
  .About_box__3hid2 p {
    font-size: 0.95em;
  }
  .About_logo__25Bpz {
    height: 30px;
    width: 40px;
  }
}

@media screen and (max-width: 600px) {
  .About_container__2Cz7Y {
    font-size: 0.8em;
  }
  .About_box2__OO2eB p,
  .About_box4__nqBQK p {
    line-height: 1.8;
  }
}
/* iPhone */
@media screen and (max-width: 420px) {
  .About_container__2Cz7Y {
    font-size: 0.75em;
  }
  .About_box2__OO2eB p,
  .About_box4__nqBQK p {
    line-height: 1.8;
  }
  .About_logosContainer__3WJwL {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1vh;
  }
  .About_skillsTitle__3V-F9 {
    padding-bottom: 2vh;
  }
  .About_title__1sRO7 {
    padding-top: 2vh;
  }
}

.Projects_container__1nh7K {
  min-height: 100%;
  width: 100%;
  color: white;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

  background-image: url(/static/media/project2.5c8299ab.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.Projects_title__1v7Tc {
  padding: 1vw 8vw 1vw;

  height: 10%;

  display: -webkit-flex;

  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 2.5em;
  border-bottom: 1px solid white;
}

.Projects_gridBox__2Ts8H {
  min-height: 90%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 5vw;

  padding-left: 8vw;
  padding-right: 8vw;
  padding-top: 4vw;
  padding-bottom: 4vw;
}

/* Media Queries */
@media screen and (max-width: 1440px) {
  .Projects_gridBox__2Ts8H {
    grid-gap: 3vw;
  }
}

@media screen and (max-width: 1200px) {
  .Projects_title__1v7Tc {
    font-size: 2em;
  }
  .Projects_gridBox__2Ts8H {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 650px) {
  .Projects_gridBox__2Ts8H {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 420px) {
  .Projects_title__1v7Tc {
    font-size: 2em;
  }
}

.ProjectCard_container__2rm8W {
  border: 4px solid white;

  color: white;
  border-radius: 25px;
  padding: 2vw;

  cursor: pointer;
  min-height: 25vh;
}

.ProjectCard_titleCard__2yIKe {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
}

.ProjectCard_titleCard__2yIKe:hover {
  font-size: 1.1em;
  transition-duration: 0.8s;
}

.ProjectCard_titleCard__2yIKe p {
  font-size: 1.8em;
  font-weight: bold;
}

.ProjectCard_infoBox__3Tp1K {
  height: 100%;
  width: 100%;

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  cursor: default;
}

.ProjectCard_infoBoxTitle__1Uzan {
  font-size: 1.5em;
  border-bottom: 2px solid white;
  padding-bottom: 0.2vh;
}

.ProjectCard_infoBoxDesc__2pTkk {
  font-size: 0.95em;
  padding-top: 1vh;
  line-height: 1.7em;
  height: 80%;
}

.ProjectCard_infoBoxLink__1fByG {
  color: white;
  text-decoration: none;
}

.ProjectCard_infoBoxLink__1fByG:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}

/* Media Queries */
@media screen and (max-width: 1920px) {
  .ProjectCard_infoBoxDesc__2pTkk {
    font-size: 1em;
  }
  .ProjectCard_infoBoxLink__1fByG {
    font-size: 1em;
  }
  .ProjectCard_titleCard__2yIKe:hover {
    font-size: 1.1em;
  }
}

@media screen and (max-width: 1600px) {
  .ProjectCard_infoBoxDesc__2pTkk {
    font-size: 0.65em;
  }
  .ProjectCard_infoBoxLink__1fByG {
    font-size: 0.7em;
  }
  .ProjectCard_titleCard__2yIKe:hover {
    font-size: 1.05em;
  }
}

@media screen and (max-width: 1440px) {
  .ProjectCard_infoBoxDesc__2pTkk {
    font-size: 0.6em;
  }
  .ProjectCard_infoBoxLink__1fByG {
    font-size: 0.7em;
  }
  .ProjectCard_titleCard__2yIKe {
    font-size: 0.85em;
  }
}

@media screen and (max-width: 1200px) {
  .ProjectCard_infoBoxDesc__2pTkk {
    font-size: 0.9em;
  }
  .ProjectCard_infoBoxLink__1fByG {
    font-size: 0.8em;
  }
  .ProjectCard_titleCard__2yIKe {
    font-size: 0.75em;
  }
}

@media screen and (max-width: 1024px) {
  .ProjectCard_container__2rm8W {
    border: 3px solid white;
  }
  .ProjectCard_infoBoxTitle__1Uzan {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 840px) {
  .ProjectCard_infoBoxDesc__2pTkk {
    font-size: 0.7em;
  }
  .ProjectCard_titleCard__2yIKe p {
    font-size: 1.4em;
  }
}

@media screen and (max-width: 420px) {
  .ProjectCard_titleCard__2yIKe p {
    font-size: 1.6em;
  }
  .ProjectCard_container__2rm8W {
    padding: 5vw;
  }
}

