* {
  padding: 0px;
  margin: 0px;
  scroll-behavior: smooth;
}

.container {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  color: white;
  font-family: "Montserrat", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");
