.container {
  border: 4px solid white;

  color: white;
  border-radius: 25px;
  padding: 2vw;

  cursor: pointer;
  min-height: 25vh;
}

.titleCard {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.titleCard:hover {
  font-size: 1.1em;
  transition-duration: 0.8s;
}

.titleCard p {
  font-size: 1.8em;
  font-weight: bold;
}

.infoBox {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  cursor: default;
}

.infoBoxTitle {
  font-size: 1.5em;
  border-bottom: 2px solid white;
  padding-bottom: 0.2vh;
}

.infoBoxDesc {
  font-size: 0.95em;
  padding-top: 1vh;
  line-height: 1.7em;
  height: 80%;
}

.infoBoxLink {
  color: white;
  text-decoration: none;
}

.infoBoxLink:hover {
  text-decoration: underline;
  text-underline-offset: 2px;
}

/* Media Queries */
@media screen and (max-width: 1920px) {
  .infoBoxDesc {
    font-size: 1em;
  }
  .infoBoxLink {
    font-size: 1em;
  }
  .titleCard:hover {
    font-size: 1.1em;
  }
}

@media screen and (max-width: 1600px) {
  .infoBoxDesc {
    font-size: 0.65em;
  }
  .infoBoxLink {
    font-size: 0.7em;
  }
  .titleCard:hover {
    font-size: 1.05em;
  }
}

@media screen and (max-width: 1440px) {
  .infoBoxDesc {
    font-size: 0.6em;
  }
  .infoBoxLink {
    font-size: 0.7em;
  }
  .titleCard {
    font-size: 0.85em;
  }
}

@media screen and (max-width: 1200px) {
  .infoBoxDesc {
    font-size: 0.9em;
  }
  .infoBoxLink {
    font-size: 0.8em;
  }
  .titleCard {
    font-size: 0.75em;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    border: 3px solid white;
  }
  .infoBoxTitle {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 840px) {
  .infoBoxDesc {
    font-size: 0.7em;
  }
  .titleCard p {
    font-size: 1.4em;
  }
}

@media screen and (max-width: 420px) {
  .titleCard p {
    font-size: 1.6em;
  }
  .container {
    padding: 5vw;
  }
}
